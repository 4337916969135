<template>
  <huboo-modal :value="value" @input="$emit('input', $event)" max-width="800px">
    <v-card v-if="value">
      <huboo-form
        @submit="onSubmit"
        :fields="fields"
        :loading="loading"
        :title="$t('billing.createPickRatePricing') | title"
        id="createPickRatePricingForm"
        showSubmit
      >
      </huboo-form>
    </v-card>
  </huboo-modal>
</template>

<script>
import { createFields } from '@/views/pages/billing/pickRatePricing/pickratePricingHelpers'
import { PICK_RATE_TYPES } from '@/utilities/constants'

const { CONTAINER, PICK_NUMBER } = PICK_RATE_TYPES

export default {
  name: 'CreatePickRatePricingModal',
  components: {},
  props: {
    loading: Boolean,
    pickRateTypeId: { type: [Number, String], required: true },
    value: Boolean,
  },
  data: () => ({}),
  computed: {
    containerTypes() {
      return this.$store.getters['billing/getContainerTypeCodes'] || []
    },
    createAction() {
      if (this.pickRateTypeId === PICK_NUMBER) return 'billing/storePickRateNumberPriceLists'
      else if (this.pickRateTypeId === CONTAINER) return 'billing/storePickRateContainerPriceLists'
      else return null
    },
    fields() {
      return createFields(this)
    },
    validationErrors() {
      return this.$store.getters['core/validationErrors']
    },
  },
  watch: {},
  created() {},
  methods: {
    async onSubmit({ values }) {
      const data = this.payload(values)
      const action = this.createAction
      await this.$store.dispatch(action, { id: this.pickRatePriceList?.id, data })
      if (!this.validationErrors) {
        this.$emit('refresh')
        this.$emit('close')
      }
    },
    payload(values) {
      const box = this.$store.getters['billing/getSelectedBox']
      return { ...values, hubooBox: box?.hubooBox }
    },
  },
}
</script>

<style lang="scss" scoped></style>
