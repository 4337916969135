import { PICK_RATE_TYPES } from '@/utilities/constants'
import { title, upperCase } from '@/utilities/filters'
import { isRequired, isRoundedToNDecimalPlaces } from '@/utilities/validations'

export const { CONTAINER, PICK_NUMBER } = PICK_RATE_TYPES
export const CONTAINER_FIELDS = [
  'containerId',
  'hubooBox',
  'id',
  'itemRatePrice',
  'skuRatePrice',
  'originRegion',
  'updatedAt',
]
export const PICK_NUMBER_FIELDS = [
  'hubooBox',
  'id',
  'itemRatePrice',
  'pickNumber',
  'originRegion',
  'updatedAt',
]

export function containerFields(context) {
  const pool = possibleFields(context)
  return CONTAINER_FIELDS.reduce((a, c) => {
    a[c] = pool[c]
    return a
  }, {})
}

export function containerItems(context) {
  return CONTAINER_FIELDS.map(f => ({ ...fields(context)[f], name: f }))
    .map(f => ({
      ...f,
      errorMessages: context.$store.getters['core/validationError'](f.name),
      text: getContainerItemValue(context, f.name),
      title: f.label,
      value:
        Object.prototype.hasOwnProperty.call(f, 'value') || context.pickRatePriceList?.[f.name],
    }))
    .filter(f => !getHiddenModalFields().includes(f.name))
}

export function containerTypeOptions(context) {
  const options = context.containerTypes.map(t => ({ text: t.description, value: t.id }))
  const placeholder = {
    disabled: true,
    text: context.$t('billing.pleaseSelectAContainerType'),
    value: null,
  }
  return [placeholder, ...options]
}

export function createFields(context) {
  return items(context)
    .filter(i => !getHiddenCreateFields(context).includes(i.name))
    .map(i => ({ ...i, value: null }))
}

export function fields(context) {
  if (context.pickRateTypeId === CONTAINER) return containerFields(context)
  else if (context.pickRateTypeId === PICK_NUMBER) return pickNumberFields(context)
  else return {}
}

export function getContainerItemValue(context, name) {
  if (name === 'containerId') return getContainerType(context)?.description
  else return context.pickRatePriceList?.[name]
}

export function getContainerType(context) {
  return context.containerTypes.find(t => t.id === context.pickRatePriceList?.containerId)
}

export function getHiddenCreateFields(context) {
  if (context.pickRateTypeId === PICK_NUMBER) return ['hubooBox', 'id', 'pickNumber', 'updatedAt']
  else if (context.pickRateTypeId === CONTAINER) return ['hubooBox', 'id', 'updatedAt']
  else return []
}

export function getHiddenModalFields() {
  return ['hubooBox', 'id']
}

export function items(context) {
  if (context.pickRateTypeId === CONTAINER) return containerItems(context)
  else if (context.pickRateTypeId === PICK_NUMBER) return pickNumberItems(context)
  else return []
}

export function pickNumberFields(context) {
  const pool = possibleFields(context)
  return PICK_NUMBER_FIELDS.reduce((a, c) => {
    a[c] = pool[c]
    return a
  }, {})
}

export function pickNumberItems(context) {
  return PICK_NUMBER_FIELDS.map(f => ({ ...fields(context)[f], name: f }))
    .map(f => ({
      ...f,
      errorMessages: context.$store.getters['core/validationError'](f.name),
      text: context.pickRatePriceList?.[f.name],
      title: f.label,
      value:
        Object.prototype.hasOwnProperty.call(f, 'value') || context.pickRatePriceList?.[f.name],
    }))
    .filter(f => !getHiddenModalFields().includes(f.name))
}

export function possibleFields(context) {
  return {
    containerId: {
      label: title(context.$t('billing.containerType')),
      component: 'v-select',
      items: containerTypeOptions(context),
      loading: context.loading,
      rules: [isRequired],
    },
    hubooBox: { label: title(context.$t('billing.hubooBox')), readonly: true },
    id: { label: upperCase(context.$t('common.id')), readonly: true },
    itemRatePrice: {
      label: title(context.$t('billing.itemRatePrice')),
      step: '.01',
      type: 'number',
      loading: context.loading,
      rules: [isRequired, v => isRoundedToNDecimalPlaces(v, 2)],
    },
    pickNumber: { label: title(context.$t('billing.pickNumber')), readonly: true },
    skuRatePrice: {
      label: title(context.$t('billing.skuRatePrice')),
      step: '.01',
      type: 'number',
      loading: context.loading,
      rules: [isRequired, v => isRoundedToNDecimalPlaces(v, 2)],
    },
    originRegion: {
      component: 'v-select',
      items: context.$store.getters['billing/getRegions']?.data?.filter(r => r.origin) || [],
      itemText: 'displayName',
      itemValue: 'code',
      label: title(context.$t('billing.originRegion')),
      loading: context.loading,
      rules: [isRequired],
    },
    updatedAt: { label: title(context.$t('common.updatedAt')), readonly: true },
  }
}
