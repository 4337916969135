<template>
  <huboo-full-screen-dialog
    :value="value"
    @input="$emit('input', $event)"
    :title="$t('billing.pickRatePricing') | title"
  >
    <huboo-list
      max-width="600px"
      class=" pa-4 mx-auto text-center"
      clickable
      @clicked="handleItemClicked"
      :items="items"
    ></huboo-list>
    <huboo-modal
      v-for="(item, index) in items"
      :key="'editModal' + index"
      maxWidth="600px"
      v-model="editModals[item.name]"
    >
      <v-card>
        <huboo-form
          :title="$t('common.edit') | title"
          @submit="onSubmit"
          :loading="loadingForm"
          :fields="[item]"
          v-if="editModals[item.name]"
        ></huboo-form>
      </v-card>
    </huboo-modal>
  </huboo-full-screen-dialog>
</template>

<script>
import {
  CONTAINER_FIELDS,
  items,
  PICK_NUMBER_FIELDS,
} from '@/views/pages/billing/pickRatePricing/pickratePricingHelpers'
import { PICK_RATE_TYPES } from '@/utilities/constants'

const { CONTAINER, PICK_NUMBER } = PICK_RATE_TYPES

export default {
  name: 'PickRatePricingModal',
  components: {},
  props: {
    urlEntity: String,
    loading: Boolean,
    pickRateTypeId: { type: [Number, String], required: true },
    value: Boolean,
  },
  data: () => ({
    editModals: [...CONTAINER_FIELDS, ...PICK_NUMBER_FIELDS].reduce((a, v) => {
      a[v] = false
      return a
    }, {}),
  }),
  computed: {
    containerTypes() {
      return this.$store.getters['billing/getContainerTypeCodes'] || []
    },
    editUrl() {
      return this.urlEntity + '/' + this.pickRatePriceList?.id
    },
    items() {
      return this.setReadOnlyItems(items(this))
    },
    loadingForm() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        url: this.editUrl,
        method: 'PATCH',
      })
    },
    pickRatePriceList() {
      return this.$store.getters['billing/getSelectedPickRatePriceList']
    },
    updateAction() {
      if (this.pickRateTypeId === PICK_NUMBER) return 'billing/patchPickRateNumberPriceLists'
      else if (this.pickRateTypeId === CONTAINER) return 'billing/patchPickRateContainerPriceLists'
      else return null
    },
    validationErrors() {
      return this.$store.getters['core/validationErrors']
    },
  },
  watch: {},
  created() {},
  methods: {
    handleItemClicked(e) {
      this.editModals[e.name] = true
    },
    async onSubmit({ values }) {
      const action = this.updateAction
      const data = this.payload(values)
      const url = this.editUrl
      await this.$store.dispatch(action, { data, id: this.pickRatePriceList?.id, url })
      if (!this.validationErrors) {
        this.$emit('refresh')
        this.resetModals()
      }
    },
    payload(values) {
      // TODO: return the values directly when PATCH operation is updated to allow partial resource updates (as it should)
      if (this.pickRateTypeId === PICK_NUMBER) return values
      else if (this.pickRateTypeId === CONTAINER) {
        const { containerId, itemRatePrice, skuRatePrice, originRegion } = this.pickRatePriceList
        return { containerId, itemRatePrice, skuRatePrice, originRegion, ...values }
      } else return null
    },
    resetModals() {
      Object.keys(this.editModals).forEach(m => {
        this.editModals[m] = false
      })
    },
    setReadOnlyItems(items) {
      const index = items.findIndex(f => f.name === 'originRegion')
      if (index !== -1) items[index].readonly = true
      return items
    },
  },
}
</script>

<style lang="scss" scoped></style>
