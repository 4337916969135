<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('billing.pickRatePricing') | title"> </huboo-page-heading>

    <huboo-table
      id="pickRatePricingTable"
      @filter="handleFilter"
      @row-clicked="onSelected"
      @search="handleSearch"
      @remove="openConfirmationModal"
      @confirm-delete="onRemove"
      @update:options="handleUpdateOptions"
      :filters="filters"
      :headers="headers"
      :items="items"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      clickable
      hide-search
      localPagination
      :remove="isAdmin"
      v-bind="options"
    >
      <template #actions>
        <export-button
          v-if="exportUrl"
          :file-name="exportName"
          :export-url="exportUrl"
          :client="box.hubooBox.toString()"
        />

        <v-btn :disabled="createModalLoading" color="primary" @click="onCreate">
          {{ $t('billing.createPickRatePricing') }}
        </v-btn>
      </template>
    </huboo-table>

    <pick-rate-pricing-modal
      v-model="modalOpen"
      :selected="selected"
      :pickRateTypeId="pickRateTypeId"
      :loading="loading"
      :urlEntity="urlEntity"
      @refresh="fetch"
    ></pick-rate-pricing-modal>
    <create-pick-rate-pricing-modal
      @close="createModalOpen = false"
      @refresh="fetch"
      :pickRateTypeId="pickRateTypeId"
      v-model="createModalOpen"
      :loading="createModalLoading"
    ></create-pick-rate-pricing-modal>
  </huboo-page>
</template>

<script>
import { formatDate, handleSearch, orderBy } from '@/views/pages/billing/billingViewHelpers'
import { title } from '@/utilities/filters'
import { goTo } from '@/utilities/helpers/router'
import { isLoading } from '@/utilities/helpers/views'
import CreatePickRatePricingModal from '@/views/pages/billing/pickRatePricing/CreatePickRatePricingModal'
import PickRatePricingModal from '@/views/pages/billing/pickRatePricing/PickRatePricingModal'
import Vue from 'vue'
import { PICK_RATE_TYPES } from '@/utilities/constants'
import ClientMixin from '@/mixins/client'
import ExportButton from '@/components/pages/ExportButton.vue'

const { CONTAINER, PICK_NUMBER } = PICK_RATE_TYPES

export default {
  name: 'PickRatePricing',
  mixins: [ClientMixin],
  components: {
    'create-pick-rate-pricing-modal': CreatePickRatePricingModal,
    'pick-rate-pricing-modal': PickRatePricingModal,
    'export-button': ExportButton,
  },
  data: () => ({
    createModalOpen: false,
    modalOpen: false,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    search: '',
    selected: null,
  }),
  computed: {
    box() {
      return this.$store.getters['billing/getSelectedBox']
    },
    createModalLoading() {
      return isLoading(this.createUrl, 'POST') || isLoading('container-type-codes')
    },
    createUrl() {
      if (this.pickRateTypeId === CONTAINER) return 'pick-rate-container-price-list/'
      else if (this.pickRateTypeId === PICK_NUMBER) return 'pick-rate-number-price-list/'
      else return ''
    },
    containerTypeHeaders() {
      return [
        {
          text: title(this.$t('billing.containerType')),
          sortable: true,
          value: 'containerTypeDescription',
        },
        { text: title(this.$t('billing.itemRatePrice')), sortable: false, value: 'itemRatePrice' },
        { text: title(this.$t('billing.skuRatePrice')), sortable: false, value: 'skuRatePrice' },
        { text: title(this.$t('billing.originRegion')), sortable: true, value: 'originRegion' },
        { text: title(this.$t('common.updated')), sortable: false, value: 'updatedAt' },
      ]
    },
    containerTypeCodes() {
      return this.$store.getters['billing/getContainerTypeCodes']
    },
    exportUrl() {
      return this.getPickRatePriceLists?.exportUrl
    },
    exportName() {
      return this.$options.name
    },
    filters() {
      // TODO: Activate when filtering available via the API
      return null
    },
    getPickRatePriceLists() {
      return this.$store.getters['billing/getPickRatePriceLists']
    },
    headers() {
      const type = this.pickRateTypeId
      if (type === CONTAINER) return this.containerTypeHeaders
      else if (type === PICK_NUMBER) return this.itemTypeHeaders
      else return []
    },
    isAdmin() {
      return this.$store.getters['core/getIsAdmin']
    },
    items() {
      return this.pickRatePriceLists.map(bh => {
        const v = { ...bh }
        v.updatedAt = this.formatDate(v.updatedAt)
        return v
      })
    },
    itemTypeHeaders() {
      return [
        { text: title(this.$t('billing.pickNumber')), sortable: true, value: 'pickNumber' },
        { text: title(this.$t('billing.itemRatePrice')), sortable: false, value: 'itemRatePrice' },
        { text: title(this.$t('billing.originRegion')), sortable: true, value: 'originRegion' },
        { text: title(this.$t('common.updated')), sortable: false, value: 'updatedAt' },
      ]
    },
    loading() {
      return isLoading(this.url) || isLoading('container-type-codes')
    },
    meta() {
      return this.getPickRatePriceLists?.meta || { total: this.items.length }
    },
    orderBy() {
      return orderBy(this)
    },
    pickRatePriceLists() {
      // return this.$store.getters['billing/getPickRatePriceLists']
      // for now, map to remove unwanted nesting
      const lists = this.getPickRatePriceLists?.data
      if (Array.isArray(lists))
        return lists.map(l => ({
          ...l,
          containerTypeDescription: this.getContainerType(l.containerId)?.description,
          loading: this.removeLoading(l.id),
        }))
      else return []
    },
    pickRateTypeId() {
      return this.box?.pickRateTypeId
    },
    removeAction() {
      const pre = 'billing/'
      switch (this.pickRateTypeId) {
        case CONTAINER:
          return pre + 'removePickRateContainerPriceLists'
        case PICK_NUMBER:
          return pre + 'removePickRateNumberPriceLists'
        default:
          return null
      }
    },
    url() {
      return '/huboo-box/' + this.box?.hubooBox + '/' + this.urlEntity
    },
    urlEntity() {
      switch (this.pickRateTypeId) {
        case CONTAINER:
          return 'pick-rate-container-price-list'
        case PICK_NUMBER:
          return 'pick-rate-number-price-list'
        default:
          return 'pick-rate-price-list'
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch('billing/fetchContainerTypeCodes', { url: 'container-type-codes' })
    this.$store.dispatch('billing/fetchRegions')
  },
  methods: {
    fetch() {
      if (!this.loading) {
        const { options, orderBy, search } = this
        const { page } = options
        const params = {}
        if (page) params.page = page
        if (search) params.query = search
        if (orderBy) params.orderBy = orderBy
        return this.$store.dispatch('billing/fetchPickRatePriceLists', { params, url: this.url })
      }
    },
    formatDate(d) {
      return formatDate(this, d)
    },
    getContainerType(id) {
      if (!Array.isArray(this.containerTypeCodes)) return null
      else return this.containerTypeCodes.find(t => t.id === id)
    },
    goTo,
    handleFilter(e) {
      // TODO: implement filtering
      console.debug('filtering...', e)
    },
    handleSearch(e) {
      return handleSearch(this, e)
    },
    handleUpdateOptions(e) {
      Vue.set(this, 'options', e)
    },
    onCreate() {
      if (!this.createModalLoading) this.createModalOpen = true
    },
    // TODO: implement edit modal for whole lines based on this
    // onEdit(e) {
    //   console.log('edit e', e)
    // },
    async onRemove() {
      await this.$store.dispatch(this.removeAction, {
        id: this.selected.id,
        url: this.removeUrl(this.selected.id),
      })
    },
    openConfirmationModal(selectedRow) {
      this.selected = selectedRow
    },
    onSelected(e) {
      this.$store.commit('billing/setSelectedPickRatePriceList', { data: e })
      this.modalOpen = true
    },
    removeLoading(id) {
      return this.$store.getters['core/apiEndpointIsLoading']({
        url: this.removeUrl(id),
        method: 'DELETE',
      })
    },
    removeUrl(id) {
      return this.urlEntity + '/' + id
    },
  },
}
</script>

<style lang="scss" scoped></style>
